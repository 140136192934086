<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button icon="check" type="primary" @click="showEditModal()">批量跟进</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>客户姓名/手机号</span>
              </template>
              <a-input v-model.trim="searchData.fuzzy" placeholder="客户姓名/手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>剩余课时</span>
              </template>
              <a-input allowClear v-model.trim="searchData.left_times" placeholder="剩余课时" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-popover placement="bottom" trigger="click" v-model="visible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <a-form-item class="block-line" label="课程顾问" >
                      <a-select allowClear v-model="searchData.distribute_id" placeholder="请选择">
                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="来源渠道" >
                      <a-tree-select
                          v-model="searchData.channel_id"
                          :tree-data="parentChannels"
                          tree-checkable
                          allowClear
                          treeNodeFilterProp="title"
                          :maxTagCount='1'
                          :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                          :show-checked-strategy="SHOW_ALL"
                          placeholder="请选择来源渠道"
                      />
                    </a-form-item>
                    <a-form-item class="block-line" label="学员状态" >
                      <a-select allowClear v-model="searchData.user_student" placeholder="请选择">
                        <a-select-option v-for="(d, index) of userStudentStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="入学筛选时间" >
                      <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" :allowClear="false" allowClear v-model="searchData.start_time" :placeholder="['开始时间', '结束时间']"/>
                    </a-form-item>
                    <a-form-item class="block-line" label="所属校区" >
                      <a-select allowClear v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' placeholder="请选择" showSearch :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="contract_id"
        :selection="{key: 'key'}"
        @change="handleChange"
        :rowSelection="{selectedRowKeys: selectedRowKeys}"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>

          <template slot="studentName" slot-scope="text, record">
          <LUser :data="record"/>
        </template>
          <template slot="action" slot-scope="text,record">
            <a href="javascript:;" @click="toProfile(record)">业务办理</a>
            <a-divider type="vertical" />
            <a href="javascript:;" @click="showEditModal(record)">跟进记录</a>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
        :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width: '200px', dataIndex: 'student_name', key: 'student_name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '签约校区', dataIndex: 'studio_id', key: '4' },
  { title: '课程科目', dataIndex: 'course_cate_id', key: '6' },
  { title: '签约课时', dataIndex: 'times', key: 'times' },
  { title: '签约赠课', dataIndex: 'gift_times', key: 'gift_times' },
  { title: '剩余课时', dataIndex: 'left_times', key: '7',sorter:()=>{} },
  { title: '剩余赠课', dataIndex: 'left_gift_times', key: '8' },
  { title: '状态', dataIndex: 'contract_status_name', key: '10' },
  { title: '操作', key: 'operation', fixed: 'right', width:160, scopedSlots: { customRender: 'action' }},
]

import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'
import moment from 'moment'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,
      channelsId:[],
      parentChannels:[],
      selectedRowKeys: [],

      userStudentStatus: C_ITEMS.userStudentStatus,
      studios: [],
      consultants: [],
      channels: [],

      searchData: {
        left_times: 10
      },

      visible: false,
      modalData: {},
      editVisible: false,
    }
  },
  mixins: [ranges,tableMixins],
  created() {
    this.getStudio()
    this.getConsultant()
    this.getSourceChannel()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },

    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },

    async getSourceChannel() {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    async getList() {
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'start_time') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('extendingAlertAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showEditModal(item) {
      if (item) {
        this.modalData = [item.student_id]
      } else {
        this.modalData = this.selectedRowKeys
      }
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.selectedRowKeys = []
      this.editVisible = false
    },
    handleChange(selectedRowKeys, filters, sorter){
      if(sorter.order){
        if(sorter.order === "ascend"){
          this.searchParams['sort'] = `${sorter.field}`
        }else{
          this.searchParams['sort'] = `-${sorter.field}`
        }
      }else{
        this.searchParams['sort'] = ''
      }
      this.getList();
    },
    toProfile(item) {
      this.$router.push(`/work/profile/${item.student_id}`)
    },
  }
}
</script>
