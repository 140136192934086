<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>续费报警</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">续费报警<a-badge :count="0" /></span>
          <xfbj v-if="activeKey==='1'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">到期预警<a-badge :count="0" /></span>
          <dqyj v-if="activeKey==='2'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import xfbj from './xfbj/index'
import dqyj from './dqyj/index'

export default {
  components: {
    xfbj,
    dqyj,
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

