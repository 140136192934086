<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="跟进" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'employee_id',
    label: '课程顾问',
    type: 'select',
    rules: [{ required: true, message: '请输入课程顾问!' }],
    items: {
      data: 'consultants',
      label: 'filter_name',
      value: 'course_consultant_id'
    }
  },
]
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Array
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '顾问跟进',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        consultants: []
      }
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    await this.getClassCategory()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getClassCategory() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.seleteItems.consultants = res.data
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.client_id = item
          }
          await this.$store.dispatch('clientAssignAction', { data: params })
          .then(res=>{
            form.resetFields()
            this.parent.hideEditModal(1)
            this.confirmLoading=false;
          })
          .catch(err=>{
            this.confirmLoading=false;
          })
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    }
  }
}
</script>
